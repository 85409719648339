.auth {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    .login-box {
        // background-color: #eaeaea;
        width: 400px;
        height: 350px;
        padding: 30px;
        border-radius: 20px;
        border: 0.5px solid #aeaeae;
        box-shadow: 0 2px 5px rgba(0,0,0,0.08);
        .login-text {
            font-weight: bold;
            color: #000;
        }
        .button {
            width: 100%;
            background-color: #4e73df;
            border-color: #4e73df;
        }
        .label {
            color: #757575;
            font-weight: bold;
        }
    }
}