.form-campaign {
  .top-row {
    align-items: center;
    flex-direction: row;
    // display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    // background-color: #09bc8a;
    ion-icon {
      font-size: 25px;
      margin-right: 15px;
      color: #000;
      
    }
    .title-container {
      // background-color: #09bc8a;
      display: flex;
      align-items: end;
      margin-bottom: 20px;
      .title {
        font-size: 25px;
        font-weight: bold;
        color: "#000";
        // margin-bottom: 30px;
      }
    }
    .delete-icon {
      ion-icon {
        font-size: 20px;
        margin-right: 15px;
        color: #757575;
      }
    }
  }
  .info-detail {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    margin-top: 10px;
    .list {
      position: relative;
      display: grid;
      //    min-height: 400px;
      background: #fff;
      padding: 20px;
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      .info-row {
        display: flex;
        flex-direction: row;
        .info-box {
          width: 350px;
          margin-right: 5px;
          .info-container {
            .title {
              color: #000;
              font-weight: bold;
              margin-bottom: 0px;
            }
            .content {
              color: #000;
            }
          }
        }
      }
      .about-container {
        width: 70%;
        .title {
          color: #000;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .content {
          color: #000;
        }
      }
      .creative-container {
        .title {
          color: #000;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .content {
          color: #000;
        }
        .creative-style {
          width: 250px;
          object-fit: contain;
          margin-right: 8px;
        }
      }
      .button {
        width: 90px;
        height: 30px;
        margin-top: 15px;
        background-color: #4e73df;
        // border: 2px solid #;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .button-text {
          margin: 0px;
          font-size: 14px;
          color: #fff;
          font-weight: bold;
        }
        ion-icon {
          margin-right: 10px;
          color: #fff;
        }
      }
    }
  }
  .progress-section {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    margin-top: 10px;
    .title {
      color: #000;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .list {
      position: relative;
      display: grid;
      //    min-height: 400px;
      background: #fff;
      padding: 20px;
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      .progress-bar {
        width: 50%;
        background-color: #fff;
        .grey {
          width: 100%;
          background-color: #eaeaea;
          height: 18px;
          border-radius: 25px;
          .green {
            width: 45%;
            background-color: #09bc8a;
            height: 18px;
            border-radius: 25px;
          }
        }
        .progress-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
          .number {
            color: #09bc8a;
            font-weight: bold;
          }
          .percent {
            color: #000;
          }
        }
      }
    }
  }
  .campaign-update {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    margin-top: 10px;
    .title {
      color: #000;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .list {
      position: relative;
      display: grid;
      //    min-height: 400px;
      background: #fff;
      padding: 20px;
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
    }
  }
  .transaction-list {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    margin-top: 10px;
    .title {
      color: #000;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .list {
      position: relative;
      display: grid;
      //    min-height: 400px;
      background: #fff;
      padding: 20px;
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      .header-container {
        display: flex;
        .search {
          position: relative;
          width: 400px;
          margin: 0 10px;
          label {
            position: relative;
            width: 100%;
            input {
              width: 100%;
              height: 40px;
              border-radius: 40px;
              padding: 5px 20px;
              padding-left: 35px;
              font-size: 18px;
              outline: none;
              border: 1px solid #ccc;
            }
            ion-icon {
              position: absolute;
              top: 10px;
              left: 10px;
              font-size: 1.2em;
              color: #aeaeae;
            }
          }
        }
        button {
          border-radius: 20px;
          border: 1px solid #ccc;
        }
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      .table-style {
        overflow: hidden;
        overflow-y: scroll;
        height: 500px;
        margin-top: 20px;
        table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;
          tr {
            border-bottom: 1px solid #eaeaea;
            padding: 20px;
          }
          thead {
            tr {
              color: #000;
              border-bottom: 1px solid #eaeaea;
              td {
                font-weight: bold;
                span {
                  color: greenyellow;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 10px 0 10px 0;
                .status-active {
                  color: #09bc8a;
                }
                .status-inactive {
                  color: red;
                }
              }
            }
          }
        }
      }
    }
  }
}
