.footer {
  padding-top: 60px;
  padding-bottom: 50px;
  .logo-mind {
    /* width: 100% !important; */
    height: 60px !important;
    /* width: 80px !important; */
    margin-bottom: 20px;
  }
  .address {
    color: #000000;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 54px;
  }
  .icons img {
    height: 16px;
    width: 18px;
    object-fit: contain;
  }
  .virtual {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    text-align: right;
    margin-bottom: 30px;
    /* max-width: 200px; */
    .virtual-web::after {
      content: "\a";
      white-space: pre;
    }
    .logo {
      height: 25px;
    }
  }
  .member {
    color: hsl(0, 0%, 0%);
    font-size: 18px;
    font-weight: 300;
    text-align: right;
    margin-bottom: 19px;
  }
  .wir img {
    width: 165px;
    height: 65px;
    text-align: right;
    margin-bottom: 51px;
  }
  .copyright ul li {
    color: #000000;
    font-size: 14px;
    font-weight: 300;
    display: inline;
    margin-left: 40px;
  }
  .copyright ul li:first-child {
    margin-left: 0 !important;
  }
  .icons ul li {
    display: inline;
    margin-left: 20px;
    object-fit: contain;
  }
  .icons ul li:first-child {
    margin-left: 0 !important;
  }
  .copyright-mobile {
    display: none;
  }
  .icons-mobile {
    display: none;
  }
  .logo-mobile {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  /* .footer .col-md-3{
        width: 50%;
    } */

  /* .footer .col-md {
        width: 50%;
    } */

  .footer {
    padding: 40px 0 45px;
  }

  .footer .address {
    font-size: 10px;
  }

  .footer .logo-mind {
    height: 40px !important;
    margin-bottom: 10px;
  }

  .footer .member {
    font-size: 10px;
    margin-bottom: 10px;
  }

  .footer .virtual {
    display: none;
    font-size: 10px;
    margin-bottom: 15px;
  }

  .footer .virtual .logo {
    height: 15px;
  }

  .footer .wir img {
    width: 91px;
    height: 36px;
  }

  .footer .icons-mobile img {
    height: 13px;
    width: 15px;
    object-fit: contain;
  }

  .footer .copyright ul li {
    font-size: 10px;
  }

  .footer .copyright {
    display: none;
  }

  .footer .copyright-mobile {
    display: inherit;
    font-size: 10px;
    font-weight: 300;
  }

  .footer .copyright-mobile > div {
    display: inherit;
    font-size: 10px;
    margin-top: 20px;
  }

  .footer .copyright-mobile ul li {
    display: inline;
    margin-left: 20px;
  }
  .footer .copyright-mobile ul li:first-child {
    margin-left: 0 !important;
  }
  .footer .logo-mobile {
    display: inherit;
    text-align: center;
    .logo {
      object-fit: contain;
      height: 20px;
    }
  }

  .footer .icons {
    display: none;
  }

  .footer .icons-mobile {
    display: inherit;
    margin-bottom: 21px;
  }

  .footer .icons-mobile ul li {
    display: inline;
    text-align: center;
    margin-left: 17px;
  }

  .footer .virtual .virtual-web::after {
    content: unset;
  }

  .footer .virtual .virtual-mobile::after {
    content: "\a";
    white-space: pre;
  }
}
