.head {
  // background-image: linear-gradient(135deg, #09bc8a, #11d69f);
  background-color: #212121;
  color: black;
  .logo {
    height: 25px;
  }
}

.head-mobile {
  display: none;
}

.container {
  /* padding: 10px; */
  margin-left: 121px;
}
.container-fluid {
  padding-top: 10px;
}
nav {
  padding: 0 60px;
  margin-bottom: 12px;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  /* background-color: black; */
}

nav li {
  float: left;
}
nav li a,
nav li a:hover {
  display: block;
  color: black;
  text-align: center;
  padding: 30px 0;
  text-decoration: none;
}

nav .navr {
  float: right;
  padding-left: 60px;
  font-size: 18px;
  font-weight: 300;
  line-height: 34px;
  cursor: pointer;
}

nav .navr .about-us-text {
  color: #ffffff;
}

nav .navr .language {
  display: flex;
  flex-direction: row;
  align-items: center;
}

nav .navr .language .cont {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  margin-left: 10px;
}

nav li a span {
  font-size: 28px;
  line-height: 34px;
  font-weight: 300;
}

.title-desc span {
  font-weight: bold;
  color: #ffffff;
}

.barracuda {
  min-height: 89vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  .title-tron {
    font-size: 60px;
    color: #fdfdfd;
    .block-color {
      background-color: #09bc8a;
      width: 200px;
      background-size: 10px;
      padding: 5px;
    }
  }
  .title-desc {
    font-size: 10px;
    font-weight: 400;
    max-width: 550px;
    margin-bottom: 0;
    color: #fdfdfd;
  }
}

.forgot-pass {
  min-height: 89vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  .title-tron {
    font-size: 40px;
    color: #fdfdfd;
    .block-color {
      background-color: #09bc8a;
      width: 200px;
      background-size: 10px;
      padding: 5px;
    }
  }
  .title-desc {
    font-size: 10px;
    font-weight: 400;
    max-width: 550px;
    margin-bottom: 0;
    color: red;
  }
}

.flag {
  width: 20px;
  height: 20px;
  margin-top: 7px;
  margin-right: 7px;
}

.navr-mobile {
  display: none;
}

.menu-view {
  display: none;
}

.menu-view-active {
  display: none;
}

.bg-mobile {
  display: none;
}

.about-us {
  background-color: #222222;
  padding: 100px 100px;
  .title-container {
    // top: 100px;
    // position: relative;
    width: 70%;
    padding: 10px;
    margin: auto;
    margin-bottom: 50px;
    .title-text {
      color: #ffffff;
      font-family: montserrat, sans-serif;
      text-align: center;
    }
  }
  .description-container {
    width: 70%;
    padding: 10px;
    margin: auto;

    .description-text {
      color: #ffffff;
      font-family: montserrat, sans-serif;
      text-align: center;
    }
    .bold {
      font-weight: bold;
    }
  }
}

.large-container {
  height: 89vh;
  padding: 50px 100px;
  .title-container {
    top: 30px;
    position: relative;
    margin-bottom: 50px;
    .title-text {
      color: #ffffff;
      font-family: montserrat, sans-serif;
      font-size: 100px;
    }
    .berimakna-text {
      color: #09bc8a;
      font-family: montserrat, sans-serif;
      font-size: 100px;
      font-weight: bolder;
    }
    .logo {
      height: 100px;
    }
  }
}

.join-us {
  // height: 60vh;
  background-color: #222222;
  padding: 100px 100px;
  min-height: 50vh;
  align-content: center;

  .title-container {
    top: 30px;
    position: relative;
    margin-bottom: 50px;
    .title-text {
      color: #ffffff;
    }
  }
  .description-container {
    // margin: auto;
    width: 70%;
    // padding: 10px;
    .description-text {
      color: #ffffff;
      font-size: 22px;
      margin-bottom: 50px;
      font-weight: 200;
    }
    .bold {
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 768px) {
  .head {
    .logo {
      height: 18px;
    }
    .barracuda {
      min-height: 87vh;
      .title-tron {
        font-size: 40px;
      }
    }
    .forgot-pass {
      min-height: 87vh;
      .title-tron {
        font-size: 26px;
      }
    }
  }
  .mstr {
    height: 44px;
    width: 56px;
  }
  .container {
    padding: 0 24px;
    margin-left: 0px;
  }

  .link-ngebul a {
    font-size: 12px;
    color: white;
    display: unset;
    color: black;
    text-align: center;
    padding: 30px 0;
    text-decoration: none;
    font-weight: 400 !important;
    line-height: 33px;
  }

  nav {
    padding: 0 25px;
  }

  nav li a span {
    font-size: 12px;
  }

  nav .navr {
    display: none;
  }

  .title-tron {
    font-size: 60px;
    font-weight: 700;
    /* line-height: 38px; */
    margin-bottom: unset;
    padding-bottom: 30px;
    margin-top: 6%;
    max-width: 320px;
    /* background-color: rgba(0, 0, 0, 0.6); */
  }
  .title-tron span::after {
    content: "\a";
    white-space: pre;
  }

  nav .link-ngebul a:hover {
    display: unset;
    color: black;
    text-align: unset;
    padding: 30px 0;
    text-decoration: unset;
  }

  .home .title-desc {
    font-size: 18px;
    font-weight: 400;
    /* line-height: 22px; */
    /* padding-bottom: 74%; */
    /* background-color: rgba(0, 0, 0, 0.6); */
  }

  /* .head .bg-respon {
        background-size: 80% !important;
        background-position: 0px !important;
        background-position-x: right !important;
        background-position-y: 513px !important;
      } */

  .bg-mobile {
    display: inherit;
    width: 250px;
    height: 250px;
    align-self: center;
    margin-bottom: 20px;
  }

  .navr-mobile {
    float: right;
    padding-left: 60px;
    font-size: 18px;
    font-weight: 300;
    line-height: 34px;
  }

  nav .navr-mobile .language {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
  }

  nav .navr-mobile .language .cont {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    margin-left: 10px;
  }

  .navr-mobile {
    display: inherit;
  }

  nav .navr-mobile .language .cont .mob-text {
    font-size: 13px;
  }

  .menu-icon {
    width: 30px;
    height: 30px;
  }

  .menu-cont {
    padding: 29px 0px;
    margin-left: 10px;
  }

  .menu-view {
    display: none;
  }

  .menu-view-active {
    height: 100vh;
    background-color: #ffffff;
    display: inherit;
    border-bottom: 1px solid #adadad;

    /* height: 100%; */
    /* overflow:unset; */
  }

  nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* background-color: black; */
  }

  nav li {
    float: left;
  }
  nav li a,
  nav li a:hover {
    display: block;
    color: black;
    text-align: center;
    padding: 30px 0;
    text-decoration: none;
  }

  nav .navr {
    float: right;
    /* padding-left: 60px;
        font-size: 18px;
        font-weight: 300;
        line-height: 34px; */
  }

  .body {
    padding: 0px 25px;
  }

  .body .menu-text {
    text-decoration: none;
    color: #000;
  }

  .body .text-style {
    border-bottom: 1px solid #adadad;
    padding-bottom: 20px;
  }

  .close-icon {
    float: right;
  }

  .about-us {
    height: 80vh;
    background-color: #222222;
    padding: 60px 40px;
    .title-container {
      text-align: left;
      top: unset;
      position: relative;
      margin-bottom: 30px;
      .title-text {
        color: #ffffff;
      }
    }
    .description-container {
      margin: auto;
      width: 100%;
      padding: 0px;
      .description-text {
        color: #ffffff;
        text-align: left;
        font-size: 18px;
        margin-bottom: 50px;
        font-weight: 200;
      }
      .bold {
        font-weight: bold;
      }
    }
  }
  .large-container {
    height: 89vh;
    padding: 50px 25px;
    .title-container {
      top: 30px;
      position: relative;
      margin-bottom: 50px;
      .title-text {
        color: #ffffff;
        font-family: montserrat, sans-serif;
        font-size: 34px;
      }
      .berimakna-text {
        color: #09bc8a;
        font-family: montserrat, sans-serif;
        font-size: 34px;
        font-weight: bolder;
      }
      .logo {
        height: 100px;
      }
    }
  }
  .join-us {
    // height: 60vh;
    background-color: #222222;
    padding: 10px 25px;
    min-height: 50vh;
    align-content: center;

    .title-container {
      top: 30px;
      position: relative;
      margin-bottom: 50px;
      .title-text {
        color: #ffffff;
      }
    }
    .description-container {
      // margin: auto;
      width: 90%;
      // padding: 10px;
      .description-text {
        color: #ffffff;
        font-size: 22px;
        margin-bottom: 50px;
        font-weight: 200;
      }
      .bold {
        font-weight: bold;
      }
    }
  }
}
