.transaction {
    .title {
        font-size: 25px;
        font-weight: bold;
        color: '#000';
        margin-bottom: 30px;
    }
    .cardBox {
        position: relative;
        width: 100%;
        padding: 20px;
        grid-template-columns: repeat(4,1fr);
        display: grid;
        gap: 40px;
        .card {
            position: relative;
            background: #fff;
            padding: 30px;
            box-shadow: 0 7px 25px rgba(0,0,0,0.08);
            border-radius: 20px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            .numbers {
                position: relative;
                font-weight: bold;
                color: #4e73df;
                font-size: 40px;
            }
            .card-row {
                flex-direction: row;
                display: flex;
                align-items: center;
                .cardName {
                    color: #000;
                    font-size: 16px;
                }
            }
        }
    }
    .transaction-list {
        position: relative;
        width: 100%;
        padding: 20px;
        display: grid;
        // grid-template-columns: 2fr 1fr;
        // gap: 30px;
        margin-top: 10px;
        .list {
           position: relative;
           display: grid;
        //    min-height: 400px;
           background: #fff;
           padding: 20px;
           box-shadow: 0 7px 25px rgba(0,0,0,0.08);
           border-radius: 20px;
           .header-container {
                display: flex;
               .search {
                    position: relative;
                    width: 400px;
                    margin: 0 10px;
                    label {
                        position: relative;
                        width: 100%;
                        input {
                            width: 100%;
                            height: 40px;
                            border-radius: 40px;
                            padding: 5px 20px;
                            padding-left: 35px;
                            font-size: 18px;
                            outline: none;
                            border: 1px solid #ccc;
                        }
                        ion-icon {
                            position: absolute;
                            top: 10px;
                            left: 10px;
                            font-size: 1.2em;
                            color: #aeaeae;
                        }
                    }
                }
               button {
                   border-radius: 20px;
                   border: 1px solid #ccc;
               }
           }
           .header {
               display: flex;
               justify-content: space-between;
               align-items: flex-start;
           }
           .table-style {
                overflow:hidden;
                overflow-y: scroll;
                height: 500px;
                margin-top: 20px;
               table {
                   width: 100%;
                   border-collapse: collapse;
                   margin-top: 10px;
                   tr {
                       border-bottom: 1px solid #eaeaea;
                       padding: 20px
                   }
                   thead {
                       tr {
                           color: #000;
                           border-bottom: 1px solid #eaeaea;
                            td {
                                font-weight: bold;
                                span {
                                    color: greenyellow;
                                }
                            }
                       }
                   }
                   tbody {
                       tr {
                         td {
                             padding: 10px 0 10px 0;
                             .status-active {
                                 color: #09bc8a;
                             }
                             .status-inactive {
                                color: red;
                            }
                         }
                    }
                   }
               }
            }
        }
    }
}