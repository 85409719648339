.campaign {
  .title {
    font-size: 25px;
    font-weight: bold;
    color: "#000";
    margin-bottom: 30px;
  }
  .button {
    a {
      text-decoration: none !important;
      // position: relative;
      // background: cyan;
      padding: 20px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      cursor: pointer;
      width: 220px;
      height: 60px;
      margin-top: 20px;
      // align-items: center;
      .cardName {
        color: #757575;
        font-size: 16px;
        font-weight: bold;
        // text-decoration: unset !important;
      }
      .iconBx {
        // position: relative;
        // display: block;
        // min-width: 60px;
        // height: 60px;
        // line-height: 60px;
        // text-align: center;
        // margin-top: 4px;
        ion-icon {
          font-size: 24px;
          color: #4e73df;
          margin-left: 10px;
        }
      }
      // .card-row {
      //     flex-direction: row;
      //     display: flex;
      //     align-items: center;
      // }
    }
  }
  .cardBox {
    position: relative;
    width: 100%;
    // padding: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 20px;
    .card {
      a {
        text-decoration: unset;
      }
      position: relative;
      background: #fff;
      padding: 30px;
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
      border-radius: 18px;
      height: 170px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .title-container {
        height: 55px;
        .title {
          position: relative;
          font-weight: bold;
          color: #4e73df;
          font-size: 18px;
          margin-bottom: 0;
        }
      }
      .cardName {
        color: #000;
        font-size: 16px;
        font-weight: bold;
      }
      .bottom-row {
        display: flex;
        flex-direction: row;
      }
      .typeName {
        color: #757575;
        font-size: 14px;
        font-weight: bold;
        width: 50%;
      }
      .status-active {
        color: #37de64;
        font-size: 14px;
        font-weight: bold;
        width: 50%;
        text-align: end;
      }
      .status-inactive {
        color: red;
        font-size: 14px;
        font-weight: bold;
        width: 50%;
        text-align: end;
      }
      .card-row {
        flex-direction: row;
        display: flex;
        align-items: center;
      }
    }
  }
  @keyframes fadeout {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0.9);
    }
  }
  @keyframes fadein {
    0% {
      opacity: 0;
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  .form-open {
    margin-top: 20px;
    background: #f2f2f2;
    border: 1px solid rgba(0, 0, 0, 0.08);
    padding: 20px;
    // animation: fadein 500ms;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    // transition: height 0.25s linear;
    .title-row {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      .cardName {
        color: #757575;
        font-size: 16px;
        font-weight: bold;
      }
      .iconBx {
        ion-icon {
          font-size: 24px;
          color: red;
          margin-left: 10px;
        }
      }
    }
    .card-row {
      // flex-direction: row;
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      width: 100%;
      // padding: 20px;
      grid-template-columns: repeat(4, 1fr);
      display: grid;
      // gap: 40px;
      .cardName {
        color: #000;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .select {
      margin-bottom: 20px;
    }
  }
  .form-close {
    animation: fadeout 500ms;
    // transition: height 0.25s linear;
  }
  .accordion {
    margin-top: 20px;
    .body {
      background: #eaeaea;
      min-height: min-content;
    }
  }
  .tes {
    font-size: 20px;
    color: #000;
    font-weight: bold;
  }
}
