.verification {
  background-image: linear-gradient(135deg, #09bc8a, #11d69f);
  color: black;
  .logo {
    height: 25px;
  }
}

.head-mobile {
  display: none;
}

.container {
  margin-left: 121px;
}
.container-fluid {
  padding-top: 10px;
}
nav {
  padding: 0 60px;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

nav li {
  float: left;
}
nav li a,
nav li a:hover {
  display: block;
  color: black;
  text-align: center;
  padding: 30px 0;
  text-decoration: none;
}

nav .navr {
  float: right;
  padding-left: 60px;
  font-size: 18px;
  font-weight: 300;
  line-height: 34px;
  cursor: pointer;
}

nav li a span {
  font-size: 28px;
  line-height: 34px;
  font-weight: 300;
}

.title-desc span {
  font-weight: bold;
}

.barracuda {
  min-height: 89vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .title-desc {
    font-size: 20px;
    font-weight: 400;
    /* line-height: 26px; */
    max-width: 554px;
    margin-bottom: 0;
    color: #fdfdfd;
  }
}

.title-tron {
  font-size: 36px;
  font-weight: 700;
  color: #ffffff;
  /* line-height: 43px; */
  margin-bottom: 30px;
  max-width: 625px;
  /* margin-top: 30px; */
}

.flag {
  width: 20px;
  height: 20px;
  margin-top: 7px;
  margin-right: 7px;
}

.navr-mobile {
  display: none;
}

.menu-view {
  display: none;
}

.menu-view-active {
  display: none;
}

.bg-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .mstr {
    height: 44px;
    width: 56px;
  }
  .container {
    padding: 0 24px;
    margin-left: 0px;
  }

  nav {
    padding: 0 25px;
  }

  nav li a span {
    font-size: 12px;
  }

  nav .navr {
    display: none;
  }

  .title-tron {
    font-size: 32px;
    font-weight: 700;
    /* line-height: 38px; */
    margin-bottom: unset;
    padding-bottom: 30px;
    margin-top: 6%;
    max-width: 320px;
    /* background-color: rgba(0, 0, 0, 0.6); */
  }
  .title-tron span::after {
    content: "\a";
    white-space: pre;
  }

  nav .link-ngebul a:hover {
    display: unset;
    color: black;
    text-align: unset;
    padding: 30px 0;
    text-decoration: unset;
  }

  .home .title-desc {
    font-size: 18px;
    font-weight: 400;
    /* line-height: 22px; */
    /* padding-bottom: 74%; */
    /* background-color: rgba(0, 0, 0, 0.6); */
  }

  /* .head .bg-respon {
        background-size: 80% !important;
        background-position: 0px !important;
        background-position-x: right !important;
        background-position-y: 513px !important;
      } */

  .bg-mobile {
    display: inherit;
    width: 250px;
    height: 250px;
    align-self: center;
    margin-bottom: 20px;
  }

  .navr-mobile {
    float: right;
    padding-left: 60px;
    font-size: 18px;
    font-weight: 300;
    line-height: 34px;
  }

  nav .navr-mobile .language {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
  }

  nav .navr-mobile .language .cont {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    margin-left: 10px;
  }

  .navr-mobile {
    display: inherit;
  }

  nav .navr-mobile .language .cont .mob-text {
    font-size: 13px;
  }

  .menu-icon {
    width: 30px;
    height: 30px;
  }

  .menu-cont {
    padding: 29px 0px;
    margin-left: 10px;
  }

  .menu-mobile {
  }

  .menu-view {
    display: none;
  }

  .menu-view-active {
    height: 100vh;
    background-color: #ffffff;
    display: inherit;
    border-bottom: 1px solid #adadad;

    /* height: 100%; */
    /* overflow:unset; */
  }

  nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* background-color: black; */
  }

  nav li {
    float: left;
  }
  nav li a,
  nav li a:hover {
    display: block;
    color: black;
    text-align: center;
    padding: 30px 0;
    text-decoration: none;
  }

  nav .navr {
    float: right;
    /* padding-left: 60px;
        font-size: 18px;
        font-weight: 300;
        line-height: 34px; */
  }

  .body {
    padding: 0px 25px;
  }

  .body .menu-text {
    text-decoration: none;
    color: #000;
  }

  .body .text-style {
    border-bottom: 1px solid #adadad;
    padding-bottom: 20px;
  }

  .close-icon {
    float: right;
  }
}
