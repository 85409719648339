.home {
  height: 100vh;
  position: relative;
  width: 100%;
  .navigation {
    background-color: #4e73df;
    height: 100%;
    width: 225px;
    position: fixed;
    // z-index: 1;
    // top: 0;
    left: 0;
    transition: 0.5s;
    overflow: hidden;
    ul {
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 10px;
      width: 100%;
      li {
        position: relative;
        width: 100%;
        list-style: none;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        a {
          position: relative;
          display: block;
          width: 100%;
          display: flex;
          text-decoration: none;
          color: #fff;
          font-size: 14px;
          .icon {
            position: relative;
            display: block;
            min-width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            margin-top: 4px;
            ion-icon {
              font-size: 20px;
            }
          }
          .title {
            position: relative;
            display: block;
            padding: 0 10px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            white-space: nowrap;
          }
        }
      }
      li:nth-child(1) {
        margin-bottom: 40px;
        pointer-events: none;
      }
      li:hover {
        background-color: #fff;
      }
      li:hover {
        a {
          color: #4e73df;
        }
      }
      // span {
      //     color: #000;
      // }
    }
  }
  // main
  .main {
    position: absolute;
    width: calc(100% - 220px);
    left: 225px;
    min-height: 100vh;
    background: #fff;
    transition: 0.5s;
    .topbar {
      width: 100%;
      height: 60px;
      //   display: flex;
      // justify-content: space-between;
      //   align-items: center;
      padding: 17px;
      // background: cyan;
      border-bottom-width: 1px;
      border-bottom-color: #ccc;
      // border: 1px solid #ccc;
      border-bottom: 1px solid #aeaeae;
      .toggle {
        position: relative;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        cursor: pointer;
      }
      .search {
        position: relative;
        width: 400px;
        margin: 0 10px;
        label {
          position: relative;
          width: 100%;
          input {
            width: 100%;
            height: 40px;
            border-radius: 40px;
            padding: 5px 20px;
            padding-left: 35px;
            font-size: 18px;
            outline: none;
            border: 1px solid #ccc;
          }
          ion-icon {
            position: absolute;
            top: 10px;
            left: 10px;
            font-size: 1.2em;
            color: #aeaeae;
          }
        }
      }
      .user {
        // position: relative;
        // display: flex;
        // align-self: flex-end;
        font-size: 18px;
        cursor: pointer;
        margin-right: 10px;
        text-align: end;
        align-items: center;
        // padding: 10px;
        justify-content: end;
        // align-content: center;
        // align-self: center;
        display: flex;
        ion-icon {
          margin-right: 10px;
          color: #757575;
          font-size: 30px;
        }
      }
    }
    .main-body {
      padding: 22px;
      // background-color: aqua;
    }
  }
}
