.dashboard {
  .title {
    font-size: 25px;
    font-weight: bold;
    color: "#000";
  }
  .cardBox {
    position: relative;
    width: 100%;
    padding: 20px;
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 40px;
    .card {
      position: relative;
      background: #fff;
      padding: 30px;
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .numbers {
        position: relative;
        font-weight: bold;
        color: #4e73df;
        font-size: 40px;
      }
      .card-row {
        flex-direction: row;
        display: flex;
        align-items: center;
        .cardName {
          color: #000;
          font-size: 16px;
        }
      }
    }
  }
  .cardBoxContribution {
    padding: 20px;

    .cardContribution {
      width: fit-content;
      position: relative;
      background: #fff;
      padding: 30px;
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      // display: flex;
      justify-content: space-between;
      cursor: pointer;
      .numbers {
        position: relative;
        font-weight: bold;
        color: #4e73df;
        font-size: 40px;
      }
      .card-row {
        flex-direction: row;
        display: flex;
        align-items: center;
        .cardName {
          color: #000;
          font-size: 16px;
        }
      }
    }
  }
}
