@import "./home.scss";
@import "../pages/landingpage/styles/landingPage.scss";
@import "../pages/landingpage/styles/Footer.scss";
@import "../pages/verification/styles/Verification.scss";
@import "~antd/dist/antd.css";

.body {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.action-buttons {
  display: flex;
  align-items: center;

  button {
    margin: 0 5px;
    border-radius: 6px;
    border: 1px solid #e3e3e3;
    display: flex;
    height: 35px;
    width: 35px;
    align-items: center;
    justify-content: center;
  }
}
